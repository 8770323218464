import React, { useState } from "react";
import styles from "./Search.module.css";

export default function SearchForm(props) {
  const [state, setState] = useState({
    searchInput: "",
  });

  const updateInputValue = (evt) => {
    setState({
      searchInput: evt.target.value,
    });
  };

  return (
    <div className="search">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.doSearch(state.searchInput);
        }}
      >
        <div className={styles["searchItemContainer"]}>
          <div className={styles["searchItem"]}>
            <div className={styles["searchFieldContainer"]}>
              <input
                value={state.searchInput}
                placeholder="Search for a post code"
                className={`${styles["searchInput"]} ${styles["searchInputText"]}`}
                onChange={(evt) => updateInputValue(evt)}
              ></input>
            </div>
          </div>
          <div className={styles["searchItem"]}>
            <button
              type="submit"
              className={`${styles["searchInput"]} ${styles["submitButton"]}`}
            >
              Find Postal Code
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
