import React from "react";

import { Link } from "react-router-dom";

export default function MajorCities(props) {
  const cities = [
    "Johannesburg",
    "Cape Town",
    "Durban",
    "Port Elizabeth",
    "Pretoria",
    "Soweto",
    "East London",
    "Kimberley",
    "Bloemfontein",
    "Polokwane",
    "Nelspruit",
    "Pietermaritzburg",
  ];
  return (
    <div className="item majorCities">
      <div>
        <h2>Major Cities</h2>
      </div>
      <div id="majorCitiesContainer">
        {cities.map((city) => (
          <div key={city} className="majorCitiesItem">
            <Link to={"/" + city}>{city}</Link>
          </div>
        ))}
      </div>
    </div>
  );
}
