import React from "react";
import styles from "./Spinner.module.css";

export default function Spinner() {
  return (
    <div className={styles["ldsRipple"]}>
      <div></div>
      <div></div>
    </div>
  );
}
