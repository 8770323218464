import React from "react";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
// import { isEmpty } from "lodash";

const renderRow = (item, index) => (
  <div
    itemScope
    itemType="http://schema.org/PostalAddress"
    className="resultRow"
    key={index}
  >
    <div itemProp="addressRegion" className="resultRowItem" style={{ flex: 2 }}>
      <Link to={"/" + item.city}>{item.city}</Link>
    </div>
    <div
      itemProp="addressLocality"
      className="resultRowItem"
      style={{ flex: 2 }}
    >
      <Link to={"/" + item.town}>{item.town}</Link>
    </div>
    <div
      itemProp="postalCode"
      className="resultRowItem boxOrStreetText"
      style={{ flex: 1 }}
    >
      {item.box && item.box !== "0" ? item.box : "-"}
    </div>
    <div
      itemProp="postOfficeBoxNumber"
      className="resultRowItem boxOrStreetText"
      style={{ flex: 1 }}
    >
      {item.street && item.street !== "0" ? item.street : "-"}
    </div>
  </div>
);

function ResultHeaderItem() {
  return (
    <div className="resultHeaderRow">
      <div className="resultHeaderItem" style={{ flex: 2 }}>
        City
      </div>
      <div className="resultHeaderItem" style={{ flex: 2 }}>
        Suburb
      </div>
      <div className="resultHeaderItem boxOrStreetText" style={{ flex: 1 }}>
        Box
      </div>
      <div className="resultHeaderItem boxOrStreetText" style={{ flex: 1 }}>
        Street
      </div>
    </div>
  );
}

function ResultContainer(props) {
  if (
    props.results.exact &&
    props.results.similar &&
    Object.keys(props.results.exact).length === 0 &&
    Object.keys(props.results.similar).length === 0
  )
    return (
      <div style={{ textAlign: "center" }}>
        No results here. Select a Major City or search for one
      </div>
    );
  else
    return (
      <div>
        <h2>{props.search ? "Result for " + props.search : "Result"}</h2>
        <ResultHeaderItem />
        <div id="resultView">
          {props.results &&
            props.results.exact &&
            props.results.exact.map((item, index) => renderRow(item, index))}
        </div>
        <h2>
          {props.search
            ? "Suburb post code result for " + props.search
            : "Suburbs"}
        </h2>
        <ResultHeaderItem />
        <div id="resultView">
          {props.results &&
            props.results.similar &&
            props.results.similar.map((item, index) => renderRow(item, index))}
        </div>
      </div>
    );
}

export default function Index(props) {
  return (
    <div className="item results">
      {props.loading ? (
        <div style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <ResultContainer results={props.results} search={props.search} />
      )}
      {props.error ? (
        <p>{`We got an error: ${props.errorDescription}`}</p>
      ) : null}
    </div>
  );
}
