import React, { useState, useEffect } from "react";
import SearchForm from "./SearchForm";
import Results from "./Results";
import { getPostCodesBySuburb } from "../../api";
import MajorCities from "./MajorCities";
import { useParams, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

function titleCase(str) {
  if (str) {
    return str
      .toString()
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  } else return "";
}

export default function HomePage(props) {
  const searchParams = useParams();
  // console.log("Home page search params are", searchParams);
  let search = searchParams["search"]; //fullQuery.get("city");
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [results, setResults] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      try {
        const results = await getPostCodesBySuburb(search);
        const resultBody = await results.json();
        setResults(resultBody);
        setLoading(false);
      } catch (error) {
        setError(true);
        setLoading(false);
        setErrorDescription(error);
      }
    };
    fetchData();
  }, [search]);

  return (
    <div className="fill-page">
      <Helmet>
        <title>
          {search
            ? `${titleCase(search)} postal code`
            : "South African Postal Codes"}
        </title>
        <link rel="canonical" href={`http://sapostalcodes.info/${search}`} />
        <meta
          name="description"
          content={
            search
              ? `The postal code for ${titleCase(search)} in South Africa`
              : "South African Postal Codes"
          }
        />
      </Helmet>
      <h1 className="item header">
        <a href="/">
          {search
            ? `${titleCase(search)} Postal Code`
            : "South African Postal Codes"}
        </a>
      </h1>
      <div className="item">
        <SearchForm
          doSearch={(searchTerm) => {
            history.push("/" + searchTerm);
          }}
        />
      </div>
      <MajorCities />
      <Results
        loading={loading}
        error={error}
        errorDescription={errorDescription}
        results={results}
        search={titleCase(search)}
      />
      <div className="item">
        <span className="footer">
          Problems with incorrect post codes? Or looking to advertise?
          Please&nbsp;
          <a href="mailto:mattcrox@gmail.com">let me know</a>.
        </span>
      </div>
    </div>
  );
}
